import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Man } from "../../assets/icons/man.svg";
import { ReactComponent as Rep } from "../../assets/icons/rep.svg";
import { PatientDetStyled } from "./PateintsDet.styled";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getBookingAttachmentImageAPI,
  getReportOrderAPI,
} from "../../redux/slices/generic/genericService";
import { FaCheckCircle } from "react-icons/fa";
import { BookingStyled } from "../../pages/Bookings/Bookings.styled";
import { Table } from "react-bootstrap";
import { IoMdDownload } from "react-icons/io";
import { IoEyeOffOutline } from "react-icons/io5";

const PatientDetailModal = ({
  show,
  onHide,
  patient,
  bookingId,
  bookingReport,
  prescriptionsId,
}: any) => {
  const dispatch = useDispatch();
  const [patientId, setPatientId] = useState(patient?.id);
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [showOrder, setShowOrder] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [orderItem, setOrderItem] = useState(bookingId);
  const [bookingLogs, setBookingLogs] = useState<any>([]);
  const [showLogs, setShowLogs] = useState(false);
  const BookingReportImg = patient?.attachments;

  const toggleLogs = () => {
    setShowLogs((prevShowLogs) => !prevShowLogs);
  };

  const handleCloseOrder = () => setShowOrder(false);
  const handleCloseReport = () => setShowReport(false);
  const handleShowOrder = () => setShowOrder(true);
  const handleShowReport = () => setShowReport(true);

  function getFormattedDateFromTimestamp(timestamp: any) {
    try {
      if (isNaN(timestamp)) {
        throw new Error("Invalid timestamp: Not a number");
      }
      const date = new Date(parseInt(timestamp));
      if (date.toString() === "Invalid Date") {
        throw new Error("Invalid Date: The timestamp is not valid");
      }
      const day = date.toLocaleString("en-US", { weekday: "long" });
      const formattedDate = date.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return `${formattedDate}, ${day}`;
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  }
  useEffect(() => {
    const fetchBookingLogs = async () => {
      if (!bookingId) {
        return;
      }
      const res = await dispatch(getReportOrderAPI(bookingId));
      const resJson = JSON.parse(JSON.stringify(res));
      setBookingLogs(resJson?.payload?.data);
    };
    setOrderItem(bookingId);
    fetchBookingLogs();
  }, [bookingId]);

  useEffect(() => {
    setPatientId(patient?.id);
  }, [patient]);

  function capitalizeFirstLetter(word: string = "") {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  }

  const handleImgPreview = async (url: any) => {
    window.open(url, "_blank");
  };
  const hanldeToPDF = async (userId: any) => {
    const res = await dispatch(getBookingAttachmentImageAPI(userId));
    const resJson = JSON.parse(JSON.stringify(res));
    const signedUrl = resJson?.payload;
    handleImgPreview(signedUrl?.url);
  };
  const handleDownloadImage = async (userId: any) => {
    try {
      const res = await dispatch(getBookingAttachmentImageAPI(userId));
      const resJson = JSON.parse(JSON.stringify(res));
      const signedUrl = resJson?.payload;
      if (signedUrl?.url) {
        const response = await fetch(signedUrl.url);
        if (!response.ok) {
          throw new Error("Failed to fetch the file.");
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download =
          signedUrl.filename ||
          ` ${patient?.first_name || patient?.name} ${patient?.last_name}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error while attempting to download the file:", error);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <Modal.Body>
            <PatientDetStyled>
              <div className="profile-card chk1">
                <div className="profile-info">
                  <Man />
                  <div className="profile-details">
                    <h2 className="mb-2">
                      {patient?.user?.first_name || patient?.user?.name}{" "}
                      {patient?.user?.last_name}
                    </h2>
                    <p className="mb-2">
                      Age: <span>{patient?.user?.age}</span> Gender:{" "}
                      <span>
                        {capitalizeFirstLetter(patient?.user?.gender)}
                      </span>
                      City:{" "}
                      <span>
                        {capitalizeFirstLetter(patient?.address?.city || "")}
                      </span>
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {patient?.phone ||
                          patient?.user?.secondary_phone ||
                          patient?.user?.parent?.phone}
                      </span>{" "}
                      Email:{" "}
                      <span>
                        {patient?.user?.email ||
                          patient?.user?.secondary_email ||
                          patient?.user?.parent?.email}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <button className="contact-button" onClick={handleShowOrder}>
                    Order History
                  </button>
                </div>
              </div>

              <div className="appointment-details">
                <div className="header">
                  <h2 className="mb-3">Appointment 2</h2>
                  <div className="header-right">
                    <div className="appointment-mode">
                      <span>Status :</span>{" "}
                      <span className="mode">{patient?.status}</span>
                    </div>
                    <div className="appointment-actions">
                      {patient?.attachments?.length > 0 && (
                        <button
                          className="report-button"
                          onClick={handleShowReport}
                        >
                          Report <Rep />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="appointment-info">
                  <div className="appointment-item">
                    <label>Date:</label>
                    <span>
                      {patient?.collection_1_date
                        ? getFormattedDateFromTimestamp(
                            patient.collection_1_date
                          )
                        : "N/A"}
                    </span>
                  </div>
                  <div className="appointment-item">
                    <label>Time:</label>
                    <span>{patient?.collection_1_slot || "N/A"}</span>
                  </div>
                  <div className="appointment-item">
                    <label>Purpose:</label>
                    <span>{patient?.type || "Fever & Cough"}</span>
                  </div>
                  <div className="appointment-item">
                    <label>Client:</label>
                    <span>{patient?.package?.service_name}</span>
                  </div>
                  <div className="appointment-item">
                    <label>Parent Client:</label>
                    <span>
                      {patient?.user?.client?.name
                        ? patient?.user?.client?.name
                        : patient?.user?.client?.parentClient?.name}
                    </span>
                  </div>
                  {patient?.parent && (
                    <div className="appointment-item">
                      <label>Parent:</label>
                      <div className="d-flex flex-column">
                        <span>
                          Name: {patient?.parent?.first_name}{" "}
                          {patient?.parent?.last_name}
                        </span>
                        <span>Email: {patient?.parent?.email}</span>
                        <span>Phone: {patient?.parent?.phone}</span>
                      </div>
                    </div>
                  )}

                  <div className="">
                    <label>Comments:</label>
                    {bookingLogs?.communicationLog?.map(
                      (item: any, index: number) => {
                        return <span>{patient?.client?.name || "N/A"}</span>;
                      }
                    )}

                    <div className="appointment-comments-details">
                      <div className="appointment-comment-info">
                        {bookingLogs?.bookingComments?.length > 0 ? (
                          bookingLogs?.bookingComments?.map(
                            (item: any, index: number) => {
                              return (
                                <div
                                  className="appointment-comment-item"
                                  key={index}
                                >
                                  <label>{item?.comment}</label>

                                  <li className="appointment-comment-role">
                                    {`${item?.user?.first_name} ${item?.user?.last_name} `}
                                    (
                                    {item?.user?.roles?.map(
                                      (roleObj: any, roleIndex: number) => (
                                        <span
                                          key={roleIndex}
                                          className="appointment-comment-role"
                                        >
                                          {roleObj?.role}
                                          {roleIndex <
                                          item.user.roles.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      )
                                    )}
                                    )
                                  </li>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <label>Summary:</label>
                <div className="summary">
                  {patient?.notes && <p>{patient?.notes}</p>}
                </div>
              </div>
            </PatientDetStyled>
          </Modal.Body>
        </div>
      </Modal>
      <Modal show={showOrder} onHide={handleCloseOrder}>
        <Modal.Header closeButton></Modal.Header>
        <PatientDetStyled>
          <div>
            <div className="appointment-details">
              <div className="header">
                <h2>Order ID: {bookingId}</h2>
                <div className="header-right">
                  <div className="appointment-mode">
                    <span>Status :</span>{" "}
                    <span className="mode">{patient?.status}</span>
                  </div>
                </div>
              </div>
              <div className="appointment-info mt-4">
                <div className="appointment-report-item">
                  <label>Order Placed on:</label>
                  <span>
                    {patient?.collection_1_date
                      ? getFormattedDateFromTimestamp(patient.collection_1_date)
                      : "N/A"}
                  </span>
                </div>
                <div className="appointment-report-item">
                  <label>Order Date:</label>

                  {bookingLogs?.bookingComments?.map(
                    (item: any, index: number) => {
                      return (
                        <span>
                          {patient?.collection_1_date
                            ? getFormattedDateFromTimestamp(
                                patient?.collection_1_date
                              )
                            : "N/A"}
                        </span>
                      );
                    }
                  )}
                </div>
                <div className="appointment-report-item">
                  <label onClick={toggleLogs} style={{ cursor: "pointer" }}>
                    {`Communication Logs (${bookingLogs?.communicationLogs?.length}) : `}
                  </label>
                </div>
                <div className="appointment-report-item">
                  <label>Payment Mode:</label>
                  <span>{"Offline"}</span>
                </div>
                <div className="appointment-report-item">
                  <label>Customer</label>
                  <br />
                  <label>Bill To :</label>
                  <span>
                    {patient?.user?.first_name || patient?.user?.name}{" "}
                    {patient?.user?.last_name}
                  </span>{" "}
                  <br />
                  <label>Relation :</label>
                  <span>{patient?.user?.relation || "N/A"}</span>
                  <br />
                  <label>Email :</label>
                  <span>
                    {" "}
                    {patient?.user?.email || patient?.user?.secondary_email}
                  </span>
                  <br />
                  <label>Phone :</label>
                  <span>
                    {patient?.user?.phone || patient?.user?.secondary_phone}
                  </span>
                  <br />
                  <label>Gender :</label>
                  <span>{patient?.user?.gender || "N/A"}</span>
                  <br />
                  <label>Age :</label>
                  <span>{patient?.user?.age || "N/A"}</span>
                </div>
                <div className="appointment-report-item">
                  <label>Client :</label>
                  <span>{patient?.package?.service_name}</span>
                </div>
                <div className="appointment-report-item">
                  <label>Address :</label>
                  <span>{patient?.address?.address}</span>
                </div>
              </div>
            </div>
          </div>
        </PatientDetStyled>
      </Modal>
      <Modal show={showReport} onHide={handleCloseReport}>
        <Modal.Header closeButton>
          <h5>Report's</h5>
        </Modal.Header>
        <BookingStyled>
          <div className="report-imagei-div">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>
                    {patient?.first_name || patient?.name} {patient?.last_name}
                  </th>
                  <th>View</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {BookingReportImg?.map((pdf: any, index: any) => {
                  return (
                    <tr>
                      <td>{pdf?.url}</td>
                      <td className="view-button-table">
                        <button
                          className="btn"
                          onClick={() => hanldeToPDF(pdf?.id)}
                        >
                          <IoEyeOffOutline />
                        </button>
                      </td>
                      <td className="download-button-table">
                        <button
                          className="btn"
                          onClick={() => handleDownloadImage(pdf?.id)}
                        >
                          <IoMdDownload />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </BookingStyled>
      </Modal>
    </>
  );
};

export default PatientDetailModal;
