import React, { useEffect, useState } from "react";
// import { ReactComponent as Overviewsvg } from "../../../../assets/icons/overviewsvg.svg";
import { ReactComponent as Orange } from "../../../../assets/icons/orange.svg";
import { ReactComponent as Purple } from "../../../../assets/icons/purple.svg";
import { ReactComponent as Red } from "../../../../assets/icons/red.svg";
import { ReactComponent as Green } from "../../../../assets/icons/green.svg";
import { ReactComponent as Doc } from "../../../../assets/icons/docicon.svg";
import { ReactComponent as Calg } from "../../../../assets/icons/Calg_icon.svg";
import { ReactComponent as Calb } from "../../../../assets/icons/Calb_icon.svg";
import { ReactComponent as Calr } from "../../../../assets/icons/Calr_icon.svg";
import { ReactComponent as Avoid } from "../../../../assets/icons/avoid_icon.svg";
import { ReactComponent as Set } from "../../../../assets/icons/set_icon.svg";
import { ReactComponent as Set1 } from "../../../../assets/icons/set1_icon.svg";
import { ReactComponent as Percent } from "../../../../assets/icons/percent.svg";
import { ReactComponent as Greenarrow } from "../../../../assets/icons/greenarrow.svg";
import { ReactComponent as Redarrow } from "../../../../assets/icons/redarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import PiCharts from "../PiChart/PiCharts";
import PatientDetailModal from "../../../../components/PatientDetailModal/Patientmodal";
import { useHistory } from "react-router-dom";
import DataChart from "../DataChart/DataChart";

const appointreject = [
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
];

const Overview = () => {
  const [lgShow, setLgShow] = useState(false);
  const history = useHistory();
  const [patient, setPatient] = useState({
    name: "Priyanka",
    age: "23",
    gender: "Female",
    city: "BLR",
  });
  const { dashboardInfo } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );

  const pInfo = dashboardInfo?.patientsOverview;
  const cInfo = dashboardInfo?.data;
  console.log("dashboardInfo", dashboardInfo);
  const handleModalClose = () => {
    setLgShow(false);
  };

  return (
    <>
      <div className="overview">
        <div className="overview-left" key={44}>
          <div className="patient-overview">
            <h4>Bookings Overview</h4>
            <p className="overview-icon">
              <DataChart data={dashboardInfo} />{" "}
            </p>
          </div>
        </div>
        <div
          className="overview-right"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            gap: "9px",
          }}
        >
          <div className="cards" onClick={() => history.push("/Bookings")}>
            <div className="title">
              <p className="percent"></p>
            </div>
            <div className="data">
              <Calg className="cards-icons" />

              <div className="range mt-3">
                <span>{dashboardInfo?.bookings_count}</span>
                <div className="range-text-div">
                  <span>Total Bookings</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cards"
            onClick={() => history.push("/Bookings?q=open")}
          >
            <div className="title">
              <p className="percent"></p>
            </div>
            <div className="data">
              <Calr className="cards-icons" />

              <div className="range mt-2">
                <span>{dashboardInfo?.open_bookings_count}</span>
                <div className="range-text-div">
                  <div className="range-text-div">
                    <span>Open</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cards"
            onClick={() => history.push("/Bookings?q=cancelled")}
          >
            <div className="title">
              <p className="percent"></p>
            </div>
            <div className="data">
              <Calb className="cards-icons" />

              <div className="range mt-2">
                <span> {dashboardInfo?.cancelled_bookings_count}</span>
                <div className="range-text-div">
                  <span>Cancelled Bookings</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cards"
            onClick={() => history.push("/Bookings?q=cancelled")}
          >
            <div className="title">
              <p className="Percent-icon-div"></p>
            </div>
            <div className="data">
              <Avoid className="cards-icons" />

              <div className="range mt-2">
                <span>
                  <span> {dashboardInfo?.client_not_contactable}</span>
                </span>
                <div className="range-text-div">
                  <span>Client Not Contactable</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cards"
            onClick={() =>
              history.push("/Bookings?q=client_confirmation_pending")
            }
          >
            <div className="title">
              <p className="Percent-icon-div"></p>
            </div>
            <div className="data">
              <Set className="cards-icons" />

              <div className="range mt-2 ">
                <span>
                  {" "}
                  {dashboardInfo?.client_confirmation_pending_count
                    ? dashboardInfo?.client_confirmation_pending_count
                    : 0}
                </span>
                <div className="range-text-div">
                  <span>Client Confirmation Pending</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cards"
            onClick={() => history.push("/Bookings?q=payment_pending")}
          >
            <div className="title">
              <p className=" Percent-icon-div"></p>
            </div>
            <div className="data">
              <Set1 className="cards-icons" />

              <div className="range mt-3">
                <span>
                  {dashboardInfo?.work_in_progress_count
                    ? dashboardInfo?.work_in_progress_count
                    : 0}
                </span>
                <div className="range-text-div">
                  <span> Work in Progress</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PatientDetailModal
          show={lgShow}
          onHide={handleModalClose}
          patient={patient}
        />
      </div>
    </>
  );
};

export default Overview;
