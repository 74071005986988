import React, { useState, useEffect } from "react";
import { OrderStyled } from "./Orders.Styled";
import SearchOrder from "./SearchOrder";
import OrderDetail from "./Orderdetail";
import { useDispatch, useSelector } from "react-redux";
import { getClientOrdersAPI } from "../../redux/slices/orders/orderService";
import CommonPagination from "../HRRFQ/CommonPagination";
import Loader from "../../components/Common/Loader";

const Orders = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState<any>("");
  const dispatch = useDispatch();

  const { allclientOrdersAPI } = useSelector(
    (ReduxState: any) => ReduxState.orders
  );
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchQuery);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    setIsLoading(true);
    const requestOBj: any = {
      page: page - 1,
      searchText: debouncedSearchText,
      count: pageSize,
    };
    dispatch(getClientOrdersAPI(requestOBj));
    setIsLoading(false);
  }, [dispatch, debouncedSearchText, pageSize, page]);

  return (
    <OrderStyled>
      {isLoading && <Loader />}
      <div className="order-page">
        <h2 className="sectionHeading">Orders</h2>
        <SearchOrder
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          clientOrders={allclientOrdersAPI?.clientOrders}
        />
        <OrderDetail clientOrders={allclientOrdersAPI?.clientOrders} />

        <div className="pagination-div mb-4">
          <p className="pagination-count-text">{`Total ${0} Order `}</p>
          <CommonPagination
            onChangeHnadler={(p: any, pageS: any) => {
              setPage(p);
              setPageSize(pageS);
            }}
            defaultPage={page}
            defaultTotal={allclientOrdersAPI?.clientOrdersCount}
            pageSize={pageSize}
          />
        </div>
      </div>
    </OrderStyled>
  );
};

export default Orders;
