import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { toast } from "react-toastify";
import { io, Socket } from "socket.io-client";

import {
  createUsersChatId,
  getUserChatId,
} from "../redux/slices/chat/chatService";
import { useDispatch, useSelector } from "react-redux";
import { CHAT_API } from "../config";

// Create a context with a proper type
const SocketContext = React.createContext(undefined);

const SocketProvider = ({ children }) => {
  const socket = useRef(null);

  console.log(socket, "Socket");

  const [userChatId, setUserChatId] = useState(null);

  const { user } = useSelector((ReduxState) => ReduxState.auth);

  const dispatch = useDispatch();

  const fetchChatId = useCallback(async () => {
    try {
      const res = await dispatch(
        createUsersChatId({
          body: {
            email: user?.email,
            id: user?.id,
            image: user?.image,
            name: `${user?.first_name ?? ""} ${user?.last_name ?? ""}`,
          },
        })
      );
      setUserChatId(res.payload?.data?._id);
    } catch (error) {
      console.error("Error fetching current user chat ID:", error);
      toast.error("Failed to fetch current user chat ID");
    }
  }, [user?.id, dispatch]);

  useEffect(() => {
    fetchChatId();
  }, [fetchChatId]);

  useEffect(() => {
    if (CHAT_API) {
      socket.current = io(CHAT_API);
      socket.current.emit("add-user", userChatId);
    }
  }, [CHAT_API]);

  // useEffect(() => {
  //   if (userChatId && socket.current) {
  //   }
  // }, [userChatId, socket.current]);

  return (
    <SocketContext.Provider
      value={{
        socket: socket.current ?? null,
        userChatId,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
