import React from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { FiDownloadCloud } from "react-icons/fi";
import styled from "styled-components";

const BtnCustomUpload = styled.div`
  border: 1px solid #7c4dff;
  color: #7c4dff;
`;

const DownloadCsv = (props: any) => {
  const { csvData, btnName, type } = props;
  return (
    <div>
      <CSVLink
        data={csvData}
        filename={type ? type : "bulk_upload.csv"}
        target="_blank"
      >
        <BtnCustomUpload className="btn btn-custom-upload">
          <FiDownloadCloud className="me-3" />
          {btnName ? btnName : "   Download Template"}
        </BtnCustomUpload>
      </CSVLink>
    </div>
  );
};

export default DownloadCsv;
