import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
import {
  createOrderAPI,
  getVendorsForCityAPI,
  getVendorSlotsAPI,
} from "../../redux/slices/orders/orderService";
import { CentreStyled, SlotSelectionPopupstyled } from "./Centre.styled";
import {
  Button,
  Card,
  Row,
  Col,
  Input,
  Select,
  Form,
  Table,
  Radio,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { toast } from "react-toastify";
import { FiDownloadCloud } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TimeSlotsComponent from "./TimeSlotsComponent";
import { Modal } from "react-bootstrap";
import { render } from "react-dom";
import DownloadCsv from "../../components/Common/DownloadCsv";
const { Option } = Select;

const Centre = ({
  id,
  clientId,
  showOverviewComponent,
  setShowOverviewComponent,
}: any) => {
  const dispatch = useDispatch();
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState(configInfo?.cities || []);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const history = useHistory();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [empId, setEmpId] = useState("");
  const [age, setAge] = useState("");
  const [add, setAdd] = useState("");
  const [gender, setGender] = useState("");
  const [relation, setRelation] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    name: "",
    phone: "",
    email: "",
    employee_id: "",
    remarks: "",
    age: null,
    address: "",
    gender: "",
    relation: "",
    selected_date: "",
    selected_timeslot: "",
    vendor_id: "",
  });
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const excelData = [
    [
      "name",
      "phone",
      "email",
      "employee_id",
      "remarks",
      "age",
      "address",
      "gender",
      "relation",
      "selected_date",
      "selected_timeslot",
      "vendor_id",
    ],
  ];

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    setCities(configInfo?.cities || []);
  }, [configInfo]);

  useEffect(() => {
    const userSelectedCity = localStorage.getItem("userCity");
    if (userSelectedCity) {
      const cityObj = JSON.parse(userSelectedCity);
      setSelectedCity(cityObj?.id);
      handleCitySelect(cityObj.id);
    }
  }, []);

  const handleCitySelect = async (cityName: string) => {
    setSelectedCity(cityName);
    const res = await dispatch(
      getVendorsForCityAPI({ city: cityName.toLowerCase(), packageCode: id })
    );
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error?.message) {
      toast.error(resJson?.error?.message || "Something went wrong");
      return;
    } else {
      const vendorsData = resJson.payload.data.vendorsForPackageAndCity || [];
      setVendors(vendorsData);
    }
  };

  const handleVendorSelect = (vendor: any) => {
    setSelectedVendor(vendor);
    if (employeeList.length > 0) {
      setShowOverviewComponent(true);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEmployeeDetails((prev: any) => ({
      ...prev,
      [name]:
        name === "phone"
          ? /^\d{0,10}$/.test(value)
            ? value
            : prev.phone
          : name === "age"
          ? /^\d{0,2}$/.test(value)
            ? value
            : prev.age
          : value,
    }));
  };

  const handleSelectChange = (value: string, field: string) => {
    setEmployeeDetails((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddEmployee = () => {
    setName("");
    setNumber("");
    setEmail("");
    setEmpId("");
    setAge("");
    setAdd("");
    setGender("");
    setRelation("");

    if (!employeeDetails.name.trim()) {
      setName("Name is required");
      return;
    }
    if (
      !employeeDetails.phone.trim() ||
      employeeDetails.phone.trim().length !== 10
    ) {
      setNumber("Valid 10-digit phone number is required");
      return;
    }
    if (
      !employeeDetails.email.trim() ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employeeDetails.email)
    ) {
      setEmail("Email address is required");
      return;
    }
    if (!employeeDetails.employee_id.trim()) {
      setEmpId("Employee ID is required");
      return;
    }
    if (employeeDetails.age === null || employeeDetails.age <= 0) {
      setAge("Valid age is required");
      return;
    }
    if (!employeeDetails.address.trim()) {
      setAdd("Address is required");
      return;
    }
    if (!employeeDetails.gender.trim()) {
      setGender("Select Gender.");
      return;
    }
    if (!employeeDetails.relation.trim()) {
      setRelation("Select Relation.");
      return;
    }

    setEmployeeList([...employeeList, employeeDetails]);
    setEmployeeDetails({
      name: "",
      phone: "",
      email: "",
      employee_id: "",
      remarks: "",
      age: null,
      address: "",
      gender: "",
      relation: "",
    });
  };

  const handleBulkUpload = async (e: any) => {
    const file = e?.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          setEmployeeList(jsonData as any[]);
          toast.success(`${jsonData.length} employees added successfully`);
        }
      };
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Employee ID", dataIndex: "employee_id", key: "employee_id" },
    { title: "Age", dataIndex: "age", key: "age" },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    { title: "Relation", dataIndex: "relation", key: "relation" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Remarks", dataIndex: "remarks", key: "remarks" },
    {
      title: "Scheduled Date",
      dataIndex: "selected_date",
      key: "selected_date",
      render: (date: any) => {
        console.log(date, "1122");

        if (date) {
          const d = new Date(date);
          return <>{d?.getTime() ? d?.toISOString()?.substring(0, 10) : ""}</>;
        }
        return "";
      },
    },

    {
      title: "Scheduled Time",
      dataIndex: "selected_timeslot",
      key: "selected_timeslot",
      render: (time: any) => {
        if (time) {
          return (
            <>
              {`${Math.floor(time * 24) % 12 || 12}:00 ${
                time * 24 >= 12 ? "pm" : "am"
              }`}
            </>
          );
        }
        return "";
      },
    },
    { title: "VendorID", dataIndex: "vendor_id", key: "vendor_id" },
  ];

  const [uploadType, setUploadType] = useState("single");
  const slotButtonsConfig = [
    { key: "slot1", placeholder: "Preffered Slot1" },
    { key: "slot2", placeholder: "Preffered Slot2 (Optional)" },
  ];

  const OverviewComponent = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedSlot, setSelectedSlot] = useState<any>(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [slotsAvailable, setSlotsAvailable] = useState(true);

    const handleDateChange = async (e: any) => {
      console.log("handleDateChange");

      const date = e;
      setSelectedDate(date);
      if (selectedVendor) {
        const res = await dispatch(
          getVendorSlotsAPI({
            vendorId: selectedVendor.id,
            date: moment(date).format("YYYY-MM-DD"),
          })
        );
        const resJson = JSON.parse(JSON.stringify(res));
        console.log("resJson date : ", resJson);

        if (resJson?.error?.message) {
          toast.error(resJson?.error?.message || "Something went wrong");
          return;
        } else {
          const slots = resJson.payload.data.timeslots.map(
            (slot: any) => slot.slot
          );
          console.log("slots : ", slots);
          setTimeSlots(slots);
        }
      }
    };

    const handleTimeSlotSelect = (slot: any) => {
      setSelectedSlot(slot);
    };
    const handleBookOrder = async () => {
      if (selectedDate && selectedSlot) {
        const orderDetails = {
          order: {
            client_id: clientId,
            package_code: id,
            vendor_id: selectedVendor.id,
            selected_date: moment(selectedDate).format("YYYY-MM-DD"),
            selected_timeslot: selectedSlot,
            users: employeeList.map((employee: any) => ({
              ...employee,
              selected_date: selectedDate
                ? moment(selectedDate).format("YYYY-MM-DD")
                : employee?.selected_date,
              selected_timeslot: selectedSlot
                ? selectedSlot
                : employee?.selected_timeslot,
              vendor_id: selectedVendor.id
                ? selectedVendor.id
                : employee?.vendor_id,
              phone: String(employee.phone),
              employee_id: String(employee.employee_id),
            })),
          },
        };

        console.log("orderDetails", orderDetails);
        const res = await dispatch(createOrderAPI(orderDetails));
        const resJson = JSON.parse(JSON.stringify(res));
        if (resJson?.error?.message) {
          toast.error(resJson?.error?.message || "Something went wrong");
          return;
        } else {
          toast.success("Order created successfully");
          setEmployeeDetails({
            name: "",
            phone: "",
            email: "",
            employee_id: "",
            remarks: "",
            age: null,
            address: "",
            gender: "",
            relation: "",
          });
          setEmployeeList([]);
          setShowOverviewComponent(false);
        }
      } else {
        alert("Please select both date and time slot before booking.");
      }
    };

    return (
      <SlotSelectionPopupstyled>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <h4>Select a Date:</h4>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              minDate={new Date()}
            />
          </Col>
          <Col span={12}>
            <h4>Available Time Slots:</h4>
            {loadingSlots ? (
              <div>Fetching time slots...</div>
            ) : !timeSlots || timeSlots.length === 0 ? (
              <div>No time slots available for this day</div>
            ) : (
              <TimeSlotsComponent
                timeSlots={timeSlots}
                selectedSlot={selectedSlot}
                handleClick={handleTimeSlotSelect}
              />
            )}
          </Col>
        </Row>
        <Button
          onClick={handleBookOrder}
          disabled={!selectedDate || !selectedSlot}
          style={{ marginTop: "20px" }}
        >
          Book Order
        </Button>
      </SlotSelectionPopupstyled>
    );
  };

  // const handleFinalCheckout = () => {};
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigateToPage = (url: string) => {
    if (
      employeeDetails.name === "" ||
      employeeDetails.phone === "" ||
      employeeDetails.email === ""
    ) {
      handleShow();
      return;
    }
    window.location.href = url;
  };

  console.log(employeeList[0], "employeeList");

  const handleBookOrderSubmit = async () => {
    const orderDetails = {
      order: {
        client_id: clientId,
        package_code: id,
        vendor_id: selectedVendor?.id
          ? selectedVendor?.id
          : employeeList[0]?.vendor_id,
        users: employeeList.map((employee: any) => {
          delete employee?.vendor;
          return {
            ...employee,
            selected_date: new Date(employee?.selected_date)
              ?.toISOString()
              .substring(0, 10),
            selected_timeslot: employee?.selected_timeslot
              ? `${Math.floor(employee.selected_timeslot * 24) % 12 || 12}:00 ${
                  employee.selected_timeslot * 24 >= 12 ? "pm" : "am"
                }`
              : null,

            vendor_id: selectedVendor?.id
              ? selectedVendor?.id
              : employee?.vendor_id,
            phone: employee.phone?.toString(),
            employee_id: employee.employee_id?.toString(),
          };
        }),
      },
    };
    const res = await dispatch(createOrderAPI(orderDetails));
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error?.message) {
      toast.error(resJson?.error?.message || "Something went wrong");
      return;
    } else {
      toast.success("Order created successfully");
      setEmployeeDetails({
        name: "",
        phone: "",
        email: "",
        employee_id: "",
        remarks: "",
        age: null,
        address: "",
        gender: "",
        relation: "",
      });
      setEmployeeList([]);
      setShowOverviewComponent(false);
    }
  };

  return (
    <CentreStyled>
      {!showOverviewComponent}
      <div>
        {!selectedCity && <h3>Please select a City from Navbar</h3>}
        {!showOverviewComponent && (
          <div className="create-order-title-div mt-4">
            <h5>
              Diagnostic Centers in {selectedCity} ({vendors.length || 0})
            </h5>
          </div>
        )}
        {!showOverviewComponent && vendors.length > 0 && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Form layout="vertical" style={{ maxWidth: "50%" }}>
              <Row
                style={{
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <Radio.Group
                  value={uploadType}
                  onChange={(e) => setUploadType(e.target.value)}
                >
                  <Radio
                    value="single"
                    className="create-order-checkbox-div me-4"
                  >
                    Single Upload
                  </Radio>
                  <Radio value="bulk" className="create-order-checkbox-div">
                    Bulk Upload
                  </Radio>
                </Radio.Group>
              </Row>
              {uploadType === "single" && (
                <Col className="singleUpload">
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col style={{ width: "50%" }} span={12}>
                      <Form.Item label="Name">
                        <Input
                          placeholder="Enter Employee Name"
                          name="name"
                          type="text"
                          value={employeeDetails.name}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                          required
                        />
                        {name && <span className="error">{name}</span>}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Number">
                        <Input
                          placeholder="Enter Employee Phone Number"
                          name="phone"
                          type="number"
                          value={employeeDetails.phone}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                        />
                        {number && <span className="error">{number}</span>}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col style={{ width: "50%" }} span={12}>
                      <Form.Item label="Email Id">
                        <Input
                          placeholder="Enter Employee Email Id"
                          name="email"
                          type="email"
                          value={employeeDetails.email}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                        />
                        {email && <span className="error">{email}</span>}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Employee Id">
                        <Input
                          placeholder="Enter Employee Id"
                          name="employee_id"
                          type="text"
                          value={employeeDetails.employee_id}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                        />
                        {empId && <span className="error">{empId}</span>}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col style={{ width: "50%" }} span={12}>
                      <Form.Item label="Age">
                        <Input
                          placeholder="Enter Age"
                          name="age"
                          type="number"
                          value={employeeDetails.age}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                        />
                        {age && <span className="error">{age}</span>}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Gender">
                        <Select
                          placeholder="Gender"
                          value={employeeDetails.gender}
                          onChange={(value) =>
                            handleSelectChange(value, "gender")
                          }
                          // className="form-control shadow-none"
                        >
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="others">Others</Option>
                        </Select>
                        {gender && <span className="error">{gender}</span>}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col style={{ width: "50%" }} span={12}>
                      <Form.Item label="Select Relationship">
                        <Select
                          placeholder="Select Relationship"
                          value={employeeDetails.relation}
                          onChange={(value) =>
                            handleSelectChange(value, "relation")
                          }
                        >
                          <Option value="Self">Self</Option>
                          <Option value="Spouse">Spouse</Option>
                          <Option value="Father">Father</Option>
                          <Option value="Mother">Mother</Option>
                          <Option value="Grand Mother">Grand Mother</Option>
                          <Option value="Grand Father">Grand Father</Option>
                          <Option value="Child 1">Child 1</Option>
                          <Option value="Child 2">Child 2</Option>
                          <Option value="Child 3">Child 3</Option>
                          <Option value="Sibling">Sibling</Option>
                          <Option value="Mother In Law">Mother In Law</Option>
                          <Option value="Father In Law">Father In Law</Option>
                          <Option value="Other">Other</Option>
                          <Option value="Sister">Sister</Option>
                          <Option value="Brother">Brother</Option>
                          <Option value="Daughter">Daughter</Option>
                          <Option value="Husband">Husband</Option>
                          <Option value="Son">Son</Option>
                          <Option value="Wife">Wife</Option>
                        </Select>
                        {relation && <span className="error">{relation}</span>}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Address">
                        <Input
                          placeholder="Enter Address"
                          name="address"
                          type="text"
                          value={employeeDetails.address}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                        />
                        {add && <span className="error">{add}</span>}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "100%" }}>
                      <Form.Item label="Remark">
                        <Input.TextArea
                          placeholder="Write Remark"
                          name="remarks"
                          typeof="text"
                          value={employeeDetails.remarks}
                          onChange={handleInputChange}
                          className="form-control shadow-none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {uploadType === "bulk" && (
                <Col className="bulkUpload">
                  <Form.Item label="Bulk Upload">
                    <input
                      type="file"
                      accept=".xlsx,.xls"
                      onChange={handleBulkUpload}
                    ></input>

                    <p style={{ marginTop: "10px", color: "#888" }}>
                      Upload an Excel file containing multiple employee details.
                      The file should include columns for Name, Phone, Email,
                      Employee ID, Age, Gender, Relation, Address, and Remarks.
                    </p>
                    <DownloadCsv csvData={excelData} />
                    <p style={{ marginTop: "10px", color: "#888" }}>
                      Fill up this template and upload it to add multiple
                      employees at once.
                    </p>
                  </Form.Item>
                </Col>
              )}
              {uploadType !== "bulk" && (
                <Col style={{ width: "100%" }}>
                  <div className="center-btn-div">
                    <Button type="default" onClick={handleAddEmployee}>
                      Add
                    </Button>
                  </div>
                </Col>
              )}
              {uploadType === "bulk" && (
                <div className="center-btn-div mt-5">
                  <Button onClick={handleBookOrderSubmit}>Submit</Button>
                </div>
              )}
            </Form>

            {uploadType !== "bulk" && (
              <div
                style={{
                  maxHeight: "30rem",
                  overflowY: "scroll",
                  width: "30%",
                  padding: "1rem",
                }}
              >
                <h3 className="Vendor-text-center ">Select Vendor</h3>
                <div className="">
                  {vendors.map((vendor: any) => (
                    <Col key={vendor.id} className="mb-2">
                      <Card
                        hoverable
                        className="vendor-card"
                        onClick={() => handleVendorSelect(vendor)}
                      >
                        <img
                          src={vendor.image || "https://placehold.co/400"}
                          alt={vendor.name}
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <div>
                          <h4>{vendor.name}</h4>
                          <p>Address or details can go here</p>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {showOverviewComponent && <OverviewComponent />}
      </div>

      <div className="add-user-details-table ">
        {employeeList.length > 0 && (
          <Table
            dataSource={employeeList}
            columns={columns}
            rowKey="employee_id"
            style={{ marginTop: "20px" }}
            pagination={false}
          />
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <CentreStyled>
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
            <div>
              <h5>Add The User Data</h5>
            </div>
            <button
              className="btn btn-primary wraning-btn-div mt-3"
              onClick={handleClose}
            >
              OK
            </button>
          </Modal.Body>
        </CentreStyled>
      </Modal>
    </CentreStyled>
  );
};

export default Centre;
