import React, { useState } from "react";
import { RFQRequestStyled } from "./RFQRequest.styled";
import { Input, Select, Button } from "antd";
import { Table } from "react-bootstrap";
import ConformPopUp from "./ConformPopUp";
import { MdDelete } from "react-icons/md";

const RFQRequestTable = () => {
  const [rowData, setRowData] = useState<any>([]);
  const [productRows, setProductRows] = useState([""]);
  const [counts, setCounts] = useState([0]);
  const [selectedAmounts, setSelectedAmounts] = useState([""]);
  const [selectedYear, setSelectedYear] = useState([""]);
  const [productNames, setProductNames] = useState([""]);
  const [enteredAmounts, setEnteredAmounts] = useState([""]);
  const [validationErr, setValidationErr] = useState("");
  const [showAddedRows, setShowAddedRows] = useState(false);
  const [showConformMsg, setShowConformMsg] = useState(false);

  const selectAmountOptions = [
    { value: "Wallet", label: "Set Wallet Amount" },
    { value: "discount", label: "Discount" },
  ];

  const tenureOptions = [
    { value: "1_year", label: "1 Year" },
    { value: "6_month", label: "6 Month" },
    { value: "3_month", label: "3 Month" },
  ];

  const handleIncrement = (index: number) => {
    setCounts((prev) => prev.map((c, i) => (i === index ? c + 1 : c)));
  };

  const handleDecrement = (index: number) => {
    setCounts((prev) => prev.map((c, i) => (i === index && c > 1 ? c - 1 : c)));
  };

  const handleSortAmount = (value: string, index: number) => {
    setSelectedAmounts((prev) => prev.map((a, i) => (i === index ? value : a)));
  };

  const handleSortMonth = (value: string, index: number) => {
    setSelectedYear((prev) => prev.map((y, i) => (i === index ? value : y)));
  };

  const handleSortProductName = (event: any, index: number) => {
    const value = event.target.value;
    setProductNames((prev) =>
      prev.map((name, i) => (i === index ? value : name))
    );
  };

  const handleAddRow = () => {
    setProductRows((prev) => [...prev, `Product ${prev.length + 1}`]);
    setCounts((prev) => [...prev, 1]);
    setSelectedAmounts((prev) => [...prev, ""]);
    setSelectedYear((prev) => [...prev, ""]);
    setProductNames((prev) => [...prev, ""]);
    setEnteredAmounts((prev) => [...prev, ""]);
  };

  const handleRemoveRow = (index: number) => {
    if (productRows.length > 1) {
      setProductRows((prev) => prev.filter((_, i) => i !== index));
      setCounts((prev) => prev.filter((_, i) => i !== index));
      setSelectedAmounts((prev) => prev.filter((_, i) => i !== index));
      setEnteredAmounts((prev) => prev.filter((_, i) => i !== index));
      setSelectedYear((prev) => prev.filter((_, i) => i !== index));
      setProductNames((prev) => prev.filter((_, i) => i !== index));
    }
  };
  const handleProceed = () => {
    if (productNames.length === 0) return;
    const data = productNames.map((name, index) => ({
      product: name,
      amountType: selectedAmounts[index],
      amount: enteredAmounts[index],
      count: counts[index],
      tenure: selectedYear[index],
    }));
    setRowData(data);
    setShowAddedRows(true);
  };
  return (
    <RFQRequestStyled>
      {!showAddedRows && (
        <div className="ordertable">
          <p>Wellness Subscriptions</p>
          <Table responsive="sm" bordered>
            <thead>
              <tr>
                <th>Products</th>
                <th>Limit</th>
                <th>Coverage</th>
                <th>Tenure</th>
              </tr>
            </thead>
            <tbody>
              {productRows?.map((product, index) => (
                <tr key={index}>
                  <td>
                    <Input
                      placeholder="Enter Product Name"
                      value={productNames[index]}
                      onChange={(e) => handleSortProductName(e, index)}
                    />
                  </td>
                  <td>
                    <Select
                      showSearch
                      placeholder="Sort By Amount"
                      className="me-3"
                      onChange={(value) => handleSortAmount(value, index)}
                      options={selectAmountOptions}
                    />
                    <Input
                      placeholder={`Enter ${selectedAmounts[index]} Amount`}
                      value={enteredAmounts[index]}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (!/^\d*$/.test(inputValue)) {
                          return;
                        }
                        if (
                          selectedAmounts[index] === "discount" &&
                          inputValue.length > 2
                        ) {
                          return;
                        }
                        setEnteredAmounts((prev) =>
                          prev.map((amt, i) => (i === index ? inputValue : amt))
                        );
                      }}
                    />
                  </td>
                  <td className="counter-container">
                    <span className="text-label">
                      Employee & Dependent {counts[index]}
                    </span>
                    <Button
                      className="counter-button decrement-button"
                      shape="circle"
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </Button>
                    <Button
                      className="counter-button decrement-button"
                      shape="circle"
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </Button>
                  </td>
                  <td>
                    <Select
                      showSearch
                      placeholder="Sort By Month"
                      className="me-3"
                      onChange={(value) => handleSortMonth(value, index)}
                      options={tenureOptions}
                    />

                    {productRows.length > 1 && (
                      <button
                        className="btn"
                        onClick={() => handleRemoveRow(index)}
                      >
                        <MdDelete />
                      </button>
                    )}
                    {index === productRows.length - 1 && (
                      <button
                        className="btn add-table-content"
                        onClick={handleAddRow}
                      >
                        Add
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="table-to-proceed-btn">
            <Button className="btn add-more-rfq-btn" onClick={handleProceed}>
              Proceed
            </Button>
          </div>
        </div>
      )}

      {showAddedRows && (
        <>
          <p>Your wellness Subcription RFQs</p>
          <div className="show-table-wellness-subcription-div">
            <div className="show-table-wellness-subcription-fleft">
              <Table responsive="sm" bordered className="no-outer-border">
                <thead>
                  <tr className="Subscriptions-th-div">
                    <th>
                      <img
                        src="https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732697607463.png"
                        alt=""
                      />
                      Subscriptions
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {rowData.map((row: any, index: any) => (
                    <tr key={index}>
                      <td>{row.product}</td>
                      <td>
                        {row.amountType}{" "}
                        {row.amountType !== "discount" && "Amount ₹"}
                        {row.amount}
                        {row.amountType == "discount" && "%"}
                      </td>

                      <td>{row.count} Employee + Dependents</td>
                      <td>{row.tenure}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="show-table-wellness-subcription-right">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Package Name
                </label>
                <input
                  type="text"
                  className="form-control no-box-shadow"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Employees details
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div>
                <button
                  className="btn back-more-rfq-btn mb-4"
                  onClick={() => setShowAddedRows(false)}
                >
                  Add More RFQ
                </button>
                <button className="btn add-more-rfq-btn">
                  Request For Quote
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <ConformPopUp
        show={showConformMsg}
        onHide={() => setShowConformMsg(false)}
        conformImg={
          "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732700413741.png"
        }
        title={"RFQ Submitted Successfully"}
        subTitle={"We will offer the best prices for you shortly"}
      />
    </RFQRequestStyled>
  );
};

export default RFQRequestTable;
