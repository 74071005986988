import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whats-app.svg";
import { ReactComponent as Gmail } from "../../assets/icons/gmail.svg";
import { BookingStyled } from "./Bookings.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBookingListAPI,
  getNewAllBookingListAPI,
} from "../../redux/slices/dashboard/dashboardService";
import { Dropdown } from "react-bootstrap";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import PrescriptionModal from "../Doctordashboard/Components/Appointmentorder/PrescriptionModal";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
import {
  getBookingDownloadListAPI,
  getBookingSortListAPI,
} from "../../redux/slices/generic/genericService";
import Loader from "./../../components/Common/Loader";
import { BiCommentDetail } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import CommonPagination from "../HRRFQ/CommonPagination";
import ShareMessages from "../Chat/ShareMessages";
import DownloadCsv from "../../components/Common/DownloadCsv";

const Bookings = () => {
  let location = useLocation<any>();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const orderID = queryParams.get("orderId");
  const clintOrderId = queryParams.get("clintOrderId");
  const prescriptionsId = location?.state;
  const [searchQuery, setSearchQuery] = useState(
    prescriptionsId?.booking_id || ""
  );
  const itemsPerPage = 10;
  const [bookingType, setBookingType] = useState<any>("all");
  const [prescriptionModalShow, setPrescriptionModalShow] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState<any>(null);
  const [allPrescriptions, setAllPrescriptions] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const [isDateFilterSelected, setIsDateFilterSelected] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [selectedSortKey, setSelectedSortKey] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const [selectedPatientReport, setSelectedPatientReport] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<any>({
    key: "",
    direction: "asc",
  });
  const { bookingsList, newBookingsList } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const { allBookingSortList, allBookingDownloadList } = useSelector(
    (ReduxState: any) => ReduxState.generic
  );
  const BookingSortList = allBookingSortList?.bookingStatuses;
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const parentID = user?.roles[0]?.client?.id;
  const sectionName = "hr";

  const handlePrescriptionClick = (bookingId: any, id: any) => {
    const matchedPrescription = allPrescriptions.find(
      (prescription: any) => prescription.booking_id === bookingId
    );
    const patientObj = {
      ...bookingId,
      ...bookingId?.user,
    };
    setSelectedPrescription(matchedPrescription);
    setPrescriptionModalShow(true);
    setSelectedPatientID(id);
    setSelectedPatient(patientObj);
  };
  useEffect(() => {
    const currUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const statusParam = urlParams.get("status");
    setBookingType(statusParam);
  }, []);
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return date.toLocaleString("en-IN", options);
  };
  useEffect(() => {}, [bookingsList]);

  const handleModalOpen = (patient: any, id: any) => {
    const patientObj = {
      ...patient,
      ...patient,
    };
    setSelectedPatient(patientObj);
    setSelectedPatientID(id);
    setLgShow(true);
  };
  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };
  const handleSortChange = async (key: any) => {
    setIsLoading(true);
    setSortConfig((prevSort: any) => ({
      key,
      direction:
        prevSort.key === key && prevSort.direction === "asc" ? "desc" : "asc",
    }));

    setSelectedSortKey(key);
    setIsLoading(false);
  };
  const handleShare = (platform: string, patient: any) => {
    const url = window.location.href;
    const message = `Check out this appointment details for ${patient?.first_name}:\n${url}`;

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,
          "_blank"
        );
        break;
      case "email":
        window.open(
          `mailto:?subject=Appointment Details&body=${encodeURIComponent(
            message
          )}`,
          "_self"
        );
        break;
      default:
        break;
    }
  };

  const handleDownload = () => {
    const blob = new Blob([allBookingDownloadList], { type: "text/xlsx" });
    const url = window.URL.createObjectURL(blob);
    console.log("blob : ", blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "bookings.xlsx";
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const getPackageDetails = async () => {
    setIsLoading(true);
    await dispatch(getBookingSortListAPI());
    setIsLoading(false);
  };
  useEffect(() => {
    getPackageDetails();
    dispatch(
      getBookingDownloadListAPI({
        searchText: searchQuery || "",
        dateType:
          isDateFilterSelected && selectedCheckbox
            ? `${selectedCheckbox}`
            : null,
        from: isDateFilterSelected && fromDate ? `${fromDate}` : null,
        to: isDateFilterSelected && toDate ? `${toDate}` : null,
        status: selectedSortKey,
      })
    );
  }, [
    dispatch,
    searchQuery,
    fromDate,
    toDate,
    selectedCheckbox,
    selectedSortKey,
  ]);
  const handleDateFilterChange = async (e: any) => {
    setIsLoading(true);
    const isChecked = e.target.checked;
    setIsDateFilterSelected(isChecked);
    if (isChecked) {
      await dispatch(
        getAllBookingListAPI({
          searchText: searchQuery || "",
          count: 100,
          status: selectedSortKey || "",
          dateType:
            isDateFilterSelected && selectedCheckbox
              ? `"${selectedCheckbox}"`
              : null,
          searchFromDate:
            isDateFilterSelected && fromDate ? `"${fromDate}"` : null,
          searchToDate: isDateFilterSelected && toDate ? `"${toDate}"` : null,
        })
      );
    }
    setIsLoading(false);
  };
  const getAllNewBookingsList = async () => {
    setIsLoading(true);
    const body = {} as any;
    if (clintOrderId) {
      body.clientOrderId = clintOrderId;
    }
    if (orderID || parentID) {
      body.id = orderID || parentID;
    }
    if (searchQuery) {
      body.searchText = searchQuery;
    }
    if (sectionName) {
      body.from = sectionName;
    }
    if (selectedSortKey) {
      body.status = selectedSortKey;
    }
    if (toDate && fromDate && selectedCheckbox) {
      body.dateRange = {
        from: isDateFilterSelected && fromDate ? `${fromDate}` : null,
        to: isDateFilterSelected && toDate ? `${toDate}` : null,
        dateType:
          isDateFilterSelected && selectedCheckbox
            ? `${selectedCheckbox}`
            : null,
      };
    }
    body.page = page;
    body.count = pageSize;
    await dispatch(getNewAllBookingListAPI({ filters: body }));
    setIsLoading(false);
  };
  useEffect(() => {
    getAllNewBookingsList();
  }, [
    dispatch,
    sectionName,
    searchQuery,
    page,
    pageSize,
    selectedSortKey,
    selectedCheckbox,
    clintOrderId,
    fromDate,
    toDate,
    orderID,
    parentID,
  ]);
  return (
    <BookingStyled>
      {isLoading && <Loader />}
      <div className=" booking-main-div">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="sectionHeading">Bookings</h2>
          <DownloadCsv
            btnName={"Download Bookings"}
            csvData={[[allBookingDownloadList]]}
            type={"booking_download"}
          />
        </div>
        <div className="searcharfilter mb-4">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search Patient Name or Serial No"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FaSearch />
          </div>
          <div className="filterplace">
            <div className="sort-select">
              <select
                onChange={(e) => handleSortChange(e.target.value)}
                className="shadow-none"
              >
                <option value="">Sort By</option>

                {BookingSortList?.map((item: any, index: number) => (
                  <option key={index} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="date-select"
              onClick={(e: any) => {
                if (e.target.tagName !== "INPUT") {
                  e.currentTarget.querySelector("input").click();
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <input
                type="checkbox"
                onChange={handleDateFilterChange}
                checked={isDateFilterSelected}
              />
              <p>Filter by Date (Export)</p>
            </div>
          </div>
        </div>
        {isDateFilterSelected && (
          <div className="d-flex align-items-center checkbox-container-div">
            <div className="checkbox-container">
              <div className="content">
                <label className="checkBox">
                  <input
                    type="checkbox"
                    id="scheduled"
                    checked={selectedCheckbox === "created"}
                    onChange={() => {
                      setSelectedCheckbox("created");
                    }}
                  />
                  <div className="transition"></div>
                </label>
                <label htmlFor="ch1"> Scheduled Date</label>
              </div>
              <div className="content">
                <label className="checkBox">
                  <input
                    type="checkbox"
                    id="created"
                    checked={selectedCheckbox === "scheduled"}
                    onChange={() => {
                      setSelectedCheckbox("scheduled");
                    }}
                  />
                  <div className="transition"></div>
                </label>
                <label htmlFor="ch2"> Created Date</label>
              </div>
            </div>

            <div className="checkbox-container">
              <label>From</label>
              <input
                type="date"
                name="fromDate"
                id="fromDate"
                value={fromDate || null}
                onChange={(e: any) => setFromDate(e.target.value)}
              />
              <label>To</label>
              <input
                type="date"
                name="toDate"
                id="toDate"
                value={toDate || null}
                onChange={(e: any) => setToDate(e.target.value)}
              />
            </div>
          </div>
        )}
        <div className="ordertable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th
                  onClick={() => handleSortChange("id")}
                  style={{ cursor: "pointer" }}
                >
                  Booking ID
                </th>
                <th
                  onClick={() => handleSortChange("name")}
                  style={{ cursor: "pointer" }}
                  title="View more details"
                >
                  Name
                </th>
                <th
                  onClick={() => handleSortChange("user.age")}
                  style={{ cursor: "pointer" }}
                >
                  Age
                </th>
                <th style={{ cursor: "pointer" }}>Gender</th>
                <th
                  onClick={() => handleSortChange("collection_1_date")}
                  style={{ cursor: "pointer" }}
                >
                  {selectedCheckbox === "created"
                    ? "  Scheduled Date & Time"
                    : "  Created Date & Time"}
                </th>
                <th>Visit Type</th>
                <th>Service</th>
                <th>Status</th>
                <th className="action-report">Actions</th>
              </tr>
            </thead>
            <tbody>
              {newBookingsList?.bookings?.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td
                      style={{ color: "purple", cursor: "pointer" }}
                      onClick={(e: any) => handleModalOpen(item, item.id)}
                      title="View more details"
                    >
                      {item?.user?.first_name} {item?.user?.last_name}
                    </td>
                    <td>{item?.user?.age}</td>
                    <td>{item?.user?.gender || "N/A"}</td>
                    <td>
                      {selectedCheckbox === "created"
                        ? `${formatDate(item.created_at)}`
                        : `${item.collection_1_date} ${item?.collection_1_slot}`}
                    </td>
                    <td>{item.notes || "N/A"}</td>
                    <td>{item.type || "N/A"}</td>
                    <td>{item.status || "N/A"}</td>
                    <td className="actions">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePrescriptionClick(item, item.id)}
                      >
                        <BiCommentDetail
                          title="Prescription"
                          className="fs-5"
                        />
                      </span>
                      <span className="icon-space"></span>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={Share}
                          id="dropdown-custom-components"
                        />

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleShare("whatsapp", item?.user)}
                          >
                            <Whatsapp /> WhatsApp
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleShare("email", item?.user)}
                          >
                            <Gmail /> Email
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className="icon-space"></span>
                      <ShareMessages
                        data={{
                          data: JSON.stringify({
                            "Booking ID": item?.id,
                            Name: `${item.user?.first_name || ""} ${
                              item.user?.last_name || ""
                            }`,
                            Age: item?.user?.age || "",
                            Phone: item?.user?.phone,
                            "Requested Date": item?.created_at
                              ? dayjs(Number(item?.created_at)).format(
                                  "DD/MM/YYYY"
                                )
                              : "N/A",
                            "Sheduled Date": item?.collection_1_date
                              ? dayjs(Number(item?.collection_1_date)).format(
                                  "DD/MM/YYYY"
                                )
                              : "N/A",
                            "Scheduled Slot": item?.collection_1_slot || "N/A",
                            Status: item?.status,
                            Type: item?.type,
                            Client: item?.user?.client?.name || "",
                          }),
                          isKeyValuedData: true,
                          type: "TEXT",
                        }}
                        replacePath={true}
                        tooltipTitle="Share"
                      />
                      <a href={`tel:${item?.user?.phone}`}>
                        <Call />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="pagination-div">
            <p className="pagination-count-text">{`Total ${
              newBookingsList?.totalCount || 0
            } bookings `}</p>
            <CommonPagination
              onChangeHnadler={(p: any, pageS: any) => {
                setPage(p);
                setPageSize(pageS);
              }}
              defaultPage={page}
              defaultTotal={newBookingsList?.totalCount}
              pageSize={pageSize}
            />
          </div>
        </div>

        <PatientDetailModal
          prescriptionsId={prescriptionsId}
          show={lgShow}
          onHide={handleModalClose}
          patient={selectedPatient}
          bookingId={selectedPatientID}
          bookingReport={allReports}
        />

        <PrescriptionModal
          show={prescriptionModalShow}
          onHide={() => setPrescriptionModalShow(false)}
          prescriptionData={selectedPrescription}
          bookingId={selectedPatientID}
          patient={selectedPatient}
        />
      </div>
    </BookingStyled>
  );
};

export default Bookings;
