import React, { useEffect, useState } from "react";
import { BookingStyled } from "../Bookings/Bookings.styled";
import { Modal, Table } from "react-bootstrap";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPrescriptionsAPI,
  getBookingAttachmentImageAPI,
  getReBookingAttachmentAPI,
} from "../../redux/slices/generic/genericService";
import { useHistory } from "react-router-dom";
import CommonPagination from "../HRRFQ/CommonPagination";
import Loader from "../../components/Common/Loader";

const Prescription = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [prescriptionID, setPrescriptionID] = useState("");
  const [reBookPrescriptionID, setreBookPrescriptionID] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [prescriptionImages, setPrescriptionImages] = useState<any>({});
  const userId = prescriptionID;
  const reUserID = reBookPrescriptionID;
  const handleClose = () => setShow(false);
  const [isLoading, setIsLoading] = useState(false);

  const { allPrescriptionsList } = useSelector(
    (ReduxState: any) => ReduxState.generic
  );
  const prescriptionsList = allPrescriptionsList?.bookingAttachments;

  function formatDate(dateString: any) {
    const date = new Date(Number(dateString));
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedDate} ${formattedTime}`;
  }

  const handleUrlClick = (url: any) => {
    window.open(url, "_blank");
  };

  const handleClick = (item: any) => {
    history.push("/Bookings", item);
  };

  const fetchBookingAttachmentImage = async (userId: string) => {
    const res = await dispatch(getBookingAttachmentImageAPI(userId));
    const resJson = JSON.parse(JSON.stringify(res));
    const signedUrl = resJson?.payload;
    return signedUrl?.url;
  };

  const fetchAllPrescriptionImages = async () => {
    const images = {} as any;
    for (const item of prescriptionsList) {
      if (item.id) {
        images[item.id] = await fetchBookingAttachmentImage(item.id);
      }
    }
    setPrescriptionImages(images);
  };

  const fetchReBookingAttachment = async () => {
    if (!reUserID) {
      return;
    }
    const res = await dispatch(getReBookingAttachmentAPI(reUserID));
    const resJson = JSON.parse(JSON.stringify(res));
    setShow(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAllPrescriptionImages();
    setIsLoading(false);
  }, [prescriptionsList]);

  useEffect(() => {
    setIsLoading(true);
    const requestOBj: any = {
      page: page - 1,
      searchText: search,
      count: pageSize,
      type: "bookingAttachments",
    };
    dispatch(getAllPrescriptionsAPI(requestOBj));
    setIsLoading(false);
  }, [dispatch, search, pageSize, page]);

  return (
    <BookingStyled>
      {isLoading && <Loader />}
      <div className="ordertable priscription-main-div">
        <h5>Prescriptions</h5>
        <div className="search-bar mt-4">
          <Search />
          <input
            type="text"
            placeholder="Search Prescription"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ color: "#000" }}
          />
        </div>
        <Table responsive="sm mt-4">
          <thead>
            <tr>
              <th>Id #</th>
              <th> Booking Id</th>
              <th> Uploaded By</th>
              <th> Phone</th>
              <th>Email</th>
              <th>File</th>
              <th>Uploaded On</th>
            </tr>
          </thead>
          <tbody>
            {prescriptionsList?.length > 0 ? (
              prescriptionsList.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>
                    <span
                      onClick={() => handleClick(item)}
                      style={{
                        cursor: "pointer",
                        color: "#7c4dff",
                      }}
                    >
                      {item?.booking_id ? item?.booking_id : "Not Booked"}
                    </span>
                  </td>
                  <td>
                    {item?.uploadedBy?.first_name} {item?.uploadedBy?.last_name}
                  </td>
                  <td>
                    {item?.uploadedBy?.phone ? item?.uploadedBy?.phone : "N/A"}
                  </td>
                  <td>
                    {item?.uploadedBy?.email ? item?.uploadedBy?.email : "N/A"}
                  </td>
                  <td
                    onClick={() => handleUrlClick(prescriptionImages[item?.id])}
                  >
                    <img
                      src={prescriptionImages[item?.id]}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        border: "none",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td>
                    {item?.created_at ? formatDate(item.created_at) : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No results found</td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="pagination-div">
          <p className="pagination-count-text">{`Total ${
            allPrescriptionsList?.bookingAttachmentsCount || 0
          } Prescriptions `}</p>
          <CommonPagination
            onChangeHnadler={(p: any, pageS: any) => {
              setPage(p);
              setPageSize(pageS);
            }}
            defaultPage={page}
            defaultTotal={allPrescriptionsList?.bookingAttachmentsCount}
            pageSize={pageSize}
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <BookingStyled>
          <div>
            <div>
              <h5>Create as Booking</h5>
              <p className="mt-4">
                Are you sure you want to create a booking from this
                prescription?
              </p>
            </div>
            <div className="rebooking-prescription-div mt-4">
              <button
                className="btn me-4 rebooking-cancel-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn rebooking-confirm-btn"
                onClick={() => fetchReBookingAttachment()}
              >
                Confirm
              </button>
            </div>
          </div>
        </BookingStyled>
      </Modal>
    </BookingStyled>
  );
};

export default Prescription;
