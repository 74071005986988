import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { getSubdomainNames } from "../../redux/slices/config/configService";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import {
  updateSubDomainDetails,
  updateSubDomainName,
  updateUserDetails,
} from "../../redux/slices/auth/authSlice";
import { SERVER_IP, API_KEY } from "../../config";

import { getSubDomainLoginDetails } from "../../redux/slices/auth/authService";
import { updateTablist } from "../../redux/slices/doctor/doctorSlice";
import Sidebar from "../SideMenu/Sidebar";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Common/Loader";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Layout = (props) => {
  const dispatch = useDispatch();
  const uName = window.location.pathname?.toLowerCase();
  const isSubPage = uName?.includes("/dashboard") ? true : false;
  const isSigninPage = uName === "/signin";
  const isChatPage = uName === "/chatroom";
  const query1 = useQuery();
  const userid = query1.get("userid");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      history.push("/signin");
    } else {
      getSubdomainInfo();
    }
  }, []);
  const getSubdomainInfo = async () => {
    const xKey = API_KEY;
    try {
      const patha = window.location.hostname;
      let pathName = patha?.split(".")[0] ?? "";
      console.log("pathName", pathName);
      if (pathName && (pathName != "" || pathName === "localhost")) {
        if (pathName === "localhost") {
          // pathName = "dbs";
          // pathName = "wyh";
          // pathName = "247";
        }
        console.log("patha", patha);
        const url = `${SERVER_IP}/api/v1/client/subdomain/${pathName}`;
        const headers = {
          "Content-Type": "application/json",
          "x-api-key": xKey,
        };
        setIsLoading(true);
        const response = await Axios.get(url, { headers });
        setIsLoading(false);
        console.log("elient response", response?.data);
        dispatch(updateSubDomainName(pathName));

        if (response?.data?.data?.client?.id) {
          dispatch(updateSubDomainDetails(response?.data?.data?.client));
          if (response?.data?.data?.client?.agreed_services?.hide_opd) {
            dispatch(updateTablist(["Virtual Consultation"]));
          }
          if (
            response?.data?.data?.client?.agreed_services?.allow_sso &&
            userid &&
            userid != ""
          ) {
            const data1 = { userid: userid };
            const body = { subDomain: pathName, data: { userid: userid } };
            // dispatch(getSubDomainLoginDetails(body));
            console.log("patha", patha);
            const url1 = `${SERVER_IP}/api/v1/auth/${pathName}/signon`;
            const headers = {
              "Content-Type": "application/json",
              "x-api-key": xKey,
            };
            setIsLoading(false);
            const response = await Axios.patch(url1, data1, { headers });
            console.log("response", response);
            const userD = response?.data.data;
            if (userD?.id) {
              dispatch(updateUserDetails(userD));
            }
            setIsLoading(false);
          }
        }
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <div className={"wrapper-hide-header"}>
      <Header />
      {isLoading && <Loader />}
      <div className="layout-container full-content-sec-page-design doctor-dashboard-body-content">
        {/* {props.children} */}
        {/* <div className="doctor-dashboard-body-content"> */}
        {!isSigninPage && !isChatPage && (
          <div className="left-menu-side-content">
            <Sidebar />
          </div>
        )}
        <div className="right-menu-side-content"> {props.children}</div>
      </div>
      {/* </div> */}
      {/* {!isSubPage && <Footer />} */}
    </div>
  );
};

export default Layout;
