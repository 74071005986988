import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { BookingStyled } from "../Bookings/Bookings.styled";
import { useHistory, useLocation } from "react-router-dom";
import CommonPagination from "../HRRFQ/CommonPagination";

const OrderDetail = (props: any) => {
  const { clientOrders } = props;
  const history = useHistory();

  const formatDate = (timestamp: any) => {
    if (!timestamp) return "N/A";
    const date = new Date(parseInt(timestamp, 10));
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  const handleToBookingShow = (bookId: any) => {
    history.push(
      `/Bookings?orderId=${bookId?.client?.id}&clintOrderId=${bookId?.id}`
    );
  };
  return (
    <BookingStyled>
      <div className="Order-main-div">
        <div className="ordertable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Order Id</th>
                <th>Clients</th>
                <th>Order Date</th>
                <th>Completed Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {clientOrders?.map((order: any, index: any) => (
                <tr
                  key={index}
                  onClick={() => handleToBookingShow(order)}
                  title="Click to see Bookings"
                >
                  <td>{index + 1}</td>
                  <td>{order?.id}</td>
                  <td>{order?.client.name}</td>
                  <td>{formatDate(order?.created_at)}</td>
                  <td>{formatDate(order?.invoice_date)}</td>
                  <td>{order?.status || "Pending"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </BookingStyled>
  );
};

export default OrderDetail;
