import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChatState {
  loading: boolean;
  error: any;
  chats: {};
  groups: [];
}

const initialState: ChatState = {
  loading: false,
  error: null,
  chats: [],
  groups: [],
};

export const bloodBankSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setLoading: (state: ChatState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (state: ChatState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    // getGroupList
   
  },
});

export const { setLoading, updateErrorMessage } = bloodBankSlice.actions;

export default bloodBankSlice.reducer;
