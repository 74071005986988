import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, InputGroup, Modal, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getClintUserWalletAmountDetailsAPI,
  getClintUserWalletDetailsAPI,
  getClintWalletDetailsAPI,
  signinAsUserAPI,
} from "../../redux/slices/generic/genericService";
import { BookingStyled } from "../../components/Bookings/Bookings.styled";
import { IoEyeOffOutline } from "react-icons/io5";
import CommonPagination from "../HRRFQ/CommonPagination";
import { PatientDetStyled } from "../../components/PatientDetailModal/PateintsDet.styled";
import DownloadCsv from "../../components/Common/DownloadCsv";
import Loader from "../../components/Common/Loader";

const DetailsClintWallet = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const packageData = location?.state?.packages;
  const ID = location?.state as { id: string };
  const walletId = ID?.id;
  const [showOrder, setShowOrder] = useState(false);
  const [showPakageDetails, setShowPakageDetails] = useState(false);
  const [orderItem, setOrderItem] = useState(walletId);
  const [userdetail, setdetail] = useState<any>(null);
  const [packagedetail, setPackagedetail] = useState<any>("");
  const [bookingWallet, setBookingWallet] = useState<any>([]);
  const [bookingUserWallet, setBookingUserWallet] = useState<any>([]);
  const [userAmount, setUserAmount] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectedSection, setSelectedSection] = useState("wallets");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const AllclientWalletTypes = bookingWallet?.clientWallets;
  const AllclientUserWalletTypes = bookingUserWallet?.clientUsers;
  const userId = userdetail?.id;
  const handleCloseOrder = () => setShowOrder(false);
  const handleClosePakageDetails = () => setShowPakageDetails(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowOrder = (item: any) => {
    setdetail(item);
    setShowOrder(true);
  };
  const handleShowPakageDetails = (item: any) => {
    setPackagedetail(item);
    setShowPakageDetails(true);
  };
  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (!walletId) return;
      const requestOBj: any = {
        page: page - 1,
        searchText: search,
        count: pageSize,
        walletId: walletId,
      };
      try {
        const userRes = await dispatch(
          getClintUserWalletDetailsAPI(requestOBj)
        );
        const userResJson = JSON.parse(JSON.stringify(userRes));
        setBookingUserWallet(userResJson?.payload?.data);
      } catch (error) {}
    };
    setOrderItem(walletId);
    fetchData();
    setIsLoading(true);
  }, [dispatch, walletId, pageSize, page, search]);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (!walletId) return;
      const requestOBj: any = {
        page: page - 1,
        searchText: search,
        count: pageSize,
        walletId: walletId,
      };
      try {
        const res = await dispatch(getClintWalletDetailsAPI(requestOBj));
        const resJson = JSON.parse(JSON.stringify(res));
        setBookingWallet(resJson?.payload?.data);
      } catch (error) {}
    };
    setOrderItem(walletId);
    fetchData();
    setIsLoading(false);
  }, [dispatch, walletId, pageSize, page, search]);
  useEffect(() => {
    setIsLoading(true);
    const fetchUserWallet = async () => {
      if (!userId) {
        return;
      }
      const res = await dispatch(getClintUserWalletAmountDetailsAPI(userId));
      const resJson = JSON.parse(JSON.stringify(res));
      setUserAmount(resJson?.payload?.data?.wallets);
    };
    fetchUserWallet();
    setIsLoading(false);
  }, [userId]);
  const handleCreateOrder = async (userD: any) => {
    setIsLoading(true);
    const res = (await dispatch(
      signinAsUserAPI({ userid: userD?.id?.toString() })
    )) as any;
    if (res?.payload?.data?.url) {
      window.location.href = res?.payload?.data?.url;
    }
    setIsLoading(false);
  };
  const filteredPackages = packageData.filter((item: any) =>
    item.service_name.toLowerCase().includes(search.toLowerCase())
  );
  const excelData = [
    ["Tests Name"],
    ...(packagedetail?.tests?.map((item: any) => [item?.service_name]) || []),
  ];
  const excelvendorData = [
    ["Package Name", "Vemdor Name", "Vemdor ID"],
    ...(filteredPackages?.flatMap((pkg: any) => {
      return [
        [pkg?.service_name, "", ""],
        ...pkg?.vendors?.map((vendor: any) => ["", vendor?.name, vendor?.id]),
      ];
    }) || []),
  ];

  console.log(filteredPackages, "filteredPackages");

  return (
    <BookingStyled>
      {isLoading && <Loader />}
      <div className="appoint-order">
        <div className="Details-Clint-Wallet-select-div">
          <p
            className={`me-4 ${selectedSection === "wallets" ? "active" : ""}`}
            onClick={() => setSelectedSection("wallets")}
            style={{ cursor: "pointer" }}
          >
            Client Wallets
          </p>
          <p
            className={`me-4 ${selectedSection === "users" ? "active" : ""}`}
            onClick={() => setSelectedSection("users")}
            style={{ cursor: "pointer" }}
          >
            Associated Users
          </p>
          <p
            className={`me-4 ${selectedSection === "packeges" ? "active" : ""}`}
            onClick={() => setSelectedSection("packeges")}
            style={{ cursor: "pointer" }}
          >
            Client Packeges
          </p>
          <p
            className={`${
              selectedSection === "assigned_vendor" ? "active" : ""
            }`}
            onClick={() => setSelectedSection("assigned_vendor")}
            style={{ cursor: "pointer" }}
          >
            Assigned Vendor
          </p>
        </div>

        {selectedSection === "wallets" && (
          <div className="ordertable">
            <div className="ordertable-search-div">
              <InputGroup className="mb-3 mt-2">
                <Form.Control
                  placeholder="Search associated user by name, phone, or email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="shadow-none custom-border-radius"
                  value={search}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </div>
            <Table responsive="sm mt-3">
              <thead>
                <tr>
                  <th>Wallet Name</th>
                  <th>Type</th>
                  <th>Discount </th>
                  <th>Limits</th>
                  <th>Specialization</th>
                </tr>
              </thead>
              <tbody>
                {AllclientWalletTypes?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.type}</td>
                    <td>
                      {item?.discount_percentage
                        ? `${item.discount_percentage} %`
                        : item?.amount}
                    </td>
                    <td>{"Unlimited"}</td>
                    <td>
                      {item?.specialization ? item?.specialization : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="pagination-div">
              {bookingWallet?.lenght}
              <p className="pagination-count-text">{`Total ${
                bookingWallet?.clientWalletsCount || 0
              } Client Wallet `}</p>
              <CommonPagination
                onChangeHnadler={(p: any, pageS: any) => {
                  setPage(p);
                  setPageSize(pageS);
                }}
                defaultPage={page}
                defaultTotal={bookingWallet?.clientWalletsCount}
                pageSize={pageSize}
              />
            </div>
          </div>
        )}
        {selectedSection === "users" && (
          <div className="ordertable">
            <div className="ordertable-search-div">
              <InputGroup className="mb-3 mt-2">
                <Form.Control
                  placeholder="Search associated user by name, phone, or email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="shadow-none custom-border-radius"
                  value={search}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </div>

            <Table responsive="sm mt-3">
              <thead>
                <tr>
                  <th>Contact Name</th>
                  <th>Mobile Number</th>
                  <th>Email ID</th>
                  <th>Wallet</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllclientUserWalletTypes?.length > 0 ? (
                  AllclientUserWalletTypes?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td>
                        {item?.first_name} {item?.last_name}
                      </td>
                      <td>{item?.phone ? item?.phone : "N/A"}</td>
                      <td>{item?.email ? item?.email : "N/A"}</td>
                      <td>
                        <button
                          className="btn"
                          onClick={() => handleShowOrder(item)}
                        >
                          <IoEyeOffOutline />
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn create-order-btn"
                          onClick={() => {
                            handleCreateOrder(item);
                          }}
                        >
                          Create Order
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No results found</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div className="pagination-div">
              <p className="pagination-count-text">{`Total ${
                bookingUserWallet?.clientUsersCount || 0
              } Client User `}</p>
              <CommonPagination
                onChangeHnadler={(p: any, pageS: any) => {
                  setPage(p);
                  setPageSize(pageS);
                }}
                defaultPage={page}
                defaultTotal={bookingUserWallet?.clientUsersCount}
                pageSize={pageSize}
              />
            </div>
          </div>
        )}
        {selectedSection === "packeges" && (
          <div className="ordertable">
            <div className="ordertable-search-div">
              <InputGroup className="mb-3 mt-2">
                <Form.Control
                  placeholder="Search associated user by name, phone, or email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="shadow-none custom-border-radius"
                  value={search}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </div>

            <Table responsive="sm mt-3">
              <thead>
                <tr>
                  <th>Image</th>
                  <th> Code #</th>
                  <th> Package Name</th>
                  <th> Amount</th>
                  <th>Discount Percentage</th>
                </tr>
              </thead>
              <tbody>
                {packageData?.length > 0 ? (
                  packageData?.map((item: any, index: any) => (
                    <tr
                      key={index}
                      onClick={() => handleShowPakageDetails(item)}
                      title="View More Details"
                    >
                      <td>
                        <img src={item?.image} alt={item?.service_name} />
                      </td>
                      <td>{item?.service_code}</td>
                      <td>{item?.service_name}</td>
                      <td>{item?.price?.actual_cost || 0}</td>
                      <td>{item?.price?.discounted_price || 0}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No results found</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div className="pagination-div">
              <p className="pagination-count-text">{`Total ${
                packageData?.length || 0
              } Client Packege `}</p>
              <CommonPagination
                onChangeHnadler={(p: any, pageS: any) => {
                  setPage(p);
                  setPageSize(pageS);
                }}
                defaultPage={page}
                defaultTotal={bookingUserWallet?.clientUsersCount}
                pageSize={pageSize}
              />
            </div>
          </div>
        )}
        {selectedSection === "assigned_vendor" && (
          <div className="ordertable">
            <div className="ordertable-search-div">
              <InputGroup className="mb-3 mt-2">
                <Form.Control
                  placeholder="Search associated user by name, phone, or email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="shadow-none custom-border-radius"
                  value={search}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </div>

            <div className="filter-for-vendor">
              <div className="d-flex "></div>
              <DownloadCsv
                csvData={excelvendorData}
                btnName={"Download Vendor"}
                type={"vendor_download"}
              />
            </div>

            <Table responsive="sm mt-3">
              <thead>
                <tr>
                  <th>Image</th>
                  <th> Package Name</th>
                  <th>Vendor ID</th>
                  <th> Vendor Name</th>
                </tr>
              </thead>
              <tbody>
                {/* {filteredPackages?.length > 0 ? (
                  filteredPackages?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td>
                        <img src={item?.image} alt={item?.service_name} />
                      </td>
                      <td>{item?.service_name}</td>
                      <td>
                        <div className="vendor-td-row">
                          {item?.vendors?.map((vendor: any, index: number) => (
                            <ol>{vendor?.id}</ol>
                          ))}
                        </div>
                      </td>
                      <td className="vendor-td-row">
                        <div className="vendor-td-row">
                          {item?.vendors?.map((vendor: any, index: number) => (
                            <ol>{vendor?.name}</ol>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No results found</td>
                  </tr>
                )} */}

                {filteredPackages?.length > 0 ? (
                  filteredPackages?.map((item: any, index: any) =>
                    item?.vendors?.map((vendor: any, vendorIndex: number) => (
                      <tr key={`${index}-${vendorIndex}`}>
                        <td>
                          <img src={item?.image} alt={item?.service_name} />
                        </td>
                        <td>{item?.service_name}</td>
                        <td>
                          <div className="vendor-td-row">
                            <ol>{vendor?.id}</ol>
                          </div>
                        </td>
                        <td className="vendor-td-row">
                          <div className="vendor-td-row">
                            <ol>{vendor?.name}</ol>
                          </div>
                        </td>
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td>No results found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <Modal show={showOrder} onHide={handleCloseOrder}>
        <Modal.Header closeButton>
          <h4></h4>
        </Modal.Header>
        <BookingStyled>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Wallet Type</th>
                <th>Client Wallet Amount</th>
                <th>Remaining Amount</th>
                <th>Limits</th>
              </tr>
            </thead>
            <tbody>
              {userAmount.map((wallet: any) => (
                <tr key={wallet.id}>
                  <td>{wallet.name}</td>
                  <td>
                    {wallet?.total_amount
                      ? wallet?.total_amount
                      : `${wallet.discount_percentage}%`}
                  </td>
                  <td>{wallet?.amount ? wallet?.amount : 0}</td>

                  <td>
                    {wallet.limits === 9999
                      ? "Unlimited"
                      : `${Math.floor(wallet.limits / 100)}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </BookingStyled>
      </Modal>

      <Modal show={showPakageDetails} onHide={handleClosePakageDetails}>
        <Modal.Header closeButton></Modal.Header>
        <PatientDetStyled>
          <div>
            <div className="appointment-details">
              <div className="header">
                <h2>
                  Service Code:{" "}
                  <span className="mode">{packagedetail?.service_code}</span>
                </h2>
                <div className="header-right">
                  <div className="appointment-mode">
                    <img src={packagedetail?.image} alt="" />
                  </div>
                </div>
              </div>
              <div className="appointment-info mt-4">
                <div className="appointment-report-item">
                  <label>Service Name:</label>
                  <span>{packagedetail?.service_name}</span>
                </div>

                <div className="appointment-report-item">
                  <label>
                    Actual Cost : {packagedetail?.price?.actual_cost}
                  </label>
                  <span></span> <br />
                  <label>
                    Discounted Price : {packagedetail?.price?.discounted_price}
                  </label>
                </div>
                <label>vendors :</label>
                <div className="appointment-report-item test-div">
                  {packagedetail?.tests?.map((item: any) => {
                    return (
                      <label>
                        <li>{item?.service_name}</li>
                      </label>
                    );
                  })}
                </div>
              </div>
              <div className="download-csv-div">
                <DownloadCsv
                  csvData={excelData}
                  btnName={"Download Test"}
                  type={"test_download"}
                />
              </div>
            </div>
          </div>
        </PatientDetStyled>
      </Modal>
    </BookingStyled>
  );
};

export default DetailsClintWallet;
